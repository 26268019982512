import React, { useEffect, useState, useRef } from "react";
import "./KDS.scss";
import { io } from "socket.io-client";
import KdsOrderBlock from "./KdsOrderBlock";
import { toast } from "react-toastify";

export interface KDSItem {
	id: number;
	kitchen_order_id: number;
	item_name: string;
	quantity: number;
	prepared_quantity: number;
	station: string;
	special_instructions: string | null;
	fulfilled_quantity: number;
	status: string; // Added to track item status
	order_id: number;
}

export interface KDSOrder {
	i: number;
	id: number;
	pos_order_id: number; // e.g. the original POS order ID
	order_number: number; // human-readable order number
	status: string; // 'pending', 'in_progress', 'ready', 'fulfilled'
	front_released: boolean;
	is_fulfilled: boolean;
	items: KDSItem[];
	created_at: string;
	updated_at: string;
	continued?: boolean;
	isFirst?: boolean;
	isLast?: boolean;
}

interface KDSProps {
	mode: "kitchen" | "pickup" | "front" | "recall";
}

const KDS: React.FC<KDSProps> = ({ mode = "kitchen" }) => {
	const [orders, setOrders] = useState<KDSOrder[]>([]);
	const [labeledOrders, setLabeledOrders] = useState<KDSOrder[]>([]);
	const [availableHeight, setAvailableHeight] = useState<number>(0);
	const [splitOrders, setSplitOrders] = useState<KDSOrder[]>([]);

	const processingCountRef = useRef(0); // Tracks how many order actions are in progress
	const pendingUpdateRef = useRef(false); // Marks if a socket update came in while processing

	// Ref to hold a debounce timeout for fetching orders
	const refreshTimeout = useRef<NodeJS.Timeout | null>(null);

	// Debounced function to fetch orders after optimistic updates settle
	const scheduleFetchOrders = () => {
		// Clear any existing timeout so that we only run once after a burst of actions
		if (refreshTimeout.current) {
			clearTimeout(refreshTimeout.current);
		}
		refreshTimeout.current = setTimeout(() => {
			//console.log("Debounced fetch orders after processing");
			fetchKDSOrders();
			pendingUpdateRef.current = false;
			refreshTimeout.current = null;
		}, 200); // Adjust delay as needed for smoother updates
	};

	useEffect(() => {
		//set initial window height
		const updateHeight = () => setAvailableHeight(getAvailableHeight());

		updateHeight();
		window.addEventListener("resize", updateHeight);

		return () => window.removeEventListener("resize", updateHeight);
	}, []);

	useEffect(() => {
		//connect to socket
		const endpoint = window.location.origin;
		const socket = io(endpoint);

		const handleUpdate = (data: any) => {
			if (processingCountRef.current > 0) {
				pendingUpdateRef.current = true;
				return;
			}
			//console.log("Fetching orders after socket update");
			fetchKDSOrders();
		};

		socket.on("kds_update", handleUpdate);

		socket.on("connect_error", (err) => {
			toast.error("Socket connection error: " + err.message);
		});
		return () => {
			socket.disconnect();
		};
	}, []);

	useEffect(() => {
		//handle carousel wheel events
		const carousel = document.querySelector(".kds-carousel");
		if (!carousel) return;

		const handleWheel = (e: WheelEvent) => {
			e.preventDefault();
			const scrollSpeed = 15; // Adjust this factor to increase sensitivity
			carousel.scrollLeft += e.deltaY * scrollSpeed;
		};

		carousel.addEventListener("wheel", handleWheel, { passive: false });
		return () => {
			carousel.removeEventListener("wheel", handleWheel);
		};
	}, []);

	useEffect(() => {
		//label each order with an index number for hotkeys

		if (mode == "kitchen") {
			const labeledOrders = orders
				.filter((order) => order.status === "pending")
				.map((order, index) => ({
					...order,
					i: index + 1,
				}));
			setLabeledOrders(labeledOrders);
		} else if (mode == "recall") {
			const labeledOrders = orders
				.filter((order) => order.status === "fulfilled")
				.map((order, index) => ({
					...order,
					i: index + 1,
				}));
			setLabeledOrders(labeledOrders);
		} else if (mode == "pickup") {
			const labeledOrders = orders.map((order, index) => ({
				...order,
				i: index + 1,
			}));
			setLabeledOrders(labeledOrders);
		}
	}, [orders]);

	useEffect(() => {
		//fetch initial order
		fetchKDSOrders();
	}, [mode]);

	const fetchKDSOrders = async () => {
		let orders: KDSOrder[] = [];

		processingCountRef.current += 1;

		const fetchOrders = async () => {
			try {
				const response = await fetch(`/api/kds-orders?status=pending`, {
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
				});
				const kitchenOrders = await response.json();
				orders = kitchenOrders;
			} catch (err: any) {
				toast.error("Failed to fetch kitchen orders." + err.message);
			} finally {
				processOrders();
			}
		};

		const fetchPickupOrders = async () => {
			try {
				const response = await fetch(
					`/api/kds-orders?status=ready&status2=pending`,
					{
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
						},
					}
				);
				const pickupOrders = await response.json();

				// Reconciliation logic
				pickupOrders.forEach((order: KDSOrder) => {
					if (order.status === "ready") {
						const notAllReady = order.items.some(
							(item) => item.prepared_quantity !== item.quantity
						);
						if (notAllReady) {
							handleOrderStatus(order.id, "pending", true, order);
						}
						const allFulfilled = order.items.every(
							(item) => item.fulfilled_quantity === item.quantity
						);
						if (allFulfilled) {
							handleOrderStatus(order.id, "fulfilled", true, order);
						}
					}
				});

				orders = pickupOrders;
			} catch (err: any) {
				toast.error(err.message + "Failed to fetch pickup orders.");
			} finally {
				processOrders();
			}
		};

		const fetchFulfilledOrders = async () => {
			try {
				const response = await fetch(
					`/api/kds-orders?status=fulfilled&order_by=updated_at`,
					{
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
						},
					}
				);
				const fulfilledOrders = await response.json();
				setOrders(fulfilledOrders);
			} catch (err: any) {
				toast.error(err.message + "Failed to fetch fulfilled orders.");
			}
		};

		const processOrders = () => {
			processingCountRef.current -= 1;
			if (processingCountRef.current === 0) {
				setOrders(orders);
			} else {
				pendingUpdateRef.current = true;
			}
		};

		if (mode === "pickup") {
			await fetchPickupOrders();
		} else if (mode === "recall") {
			await fetchFulfilledOrders();
		} else {
			await fetchOrders();
		}
	};

	const handleItemToggle = async (
		itemId: number,
		status: string,
		orderId: number
	) => {
		const orderIndex = orders.findIndex((order) => order.id === orderId);
		if (orderIndex === -1) {
			toast.error(`Order with id ${orderId} not found, cant update item status to ${status}`);
			return;
		}
		processingCountRef.current += 1;

		const updatedOrders = [...orders];
		const targetOrder: KDSOrder = { ...orders[orderIndex] };

		targetOrder.items = targetOrder.items.map((item) =>
			item.id === itemId
				? {
						...item,
						prepared_quantity:
							status === "ready" || status === "fulfilled" ? item.quantity : 0,
						fulfilled_quantity: status === "fulfilled" ? item.quantity : 0,
				  }
				: item
		);

		updatedOrders[orderIndex] = targetOrder;
		setOrders(updatedOrders);

		try {
			const endpoint =
				status === "fulfilled"
					? "mark-fulfilled"
					: status === "ready"
					? "mark-prepared"
					: "unmark";

			await fetch(`/api/kds-items/${itemId}/${endpoint}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
				},
			});

			if (targetOrder.status === "ready") {
				if (
					targetOrder.items.some(
						(item) => item.prepared_quantity !== item.quantity
					)
				) {
					handleOrderStatus(orderId, "pending", true, targetOrder);
				}
				if (
					targetOrder.items.every(
						(item) => item.fulfilled_quantity === item.quantity
					)
				) {
					handleOrderStatus(orderId, "fulfilled", true, targetOrder);
				}
			} else if (targetOrder.status === "pending") {
				if (
					targetOrder.items.every(
						(item) => item.prepared_quantity === item.quantity
					)
				) {
					handleOrderStatus(orderId, "ready", true, targetOrder);
				}
			} else if (targetOrder.status === "fulfilled") {
				console.log("checking fulfilled order preparedness...");
				if (
					targetOrder.items.some(
						(item) => item.prepared_quantity !== item.quantity
					)
				) {
					console.log("some items are not prepared");
					handleOrderStatus(orderId, "pending", true, targetOrder);
				} else if (
					targetOrder.items.some(
						(item) => item.fulfilled_quantity !== item.quantity
					)
				) {
					console.log("some items are not fulfilled");
					handleOrderStatus(orderId, "ready", true, targetOrder);
				} else {
					console.log("all items look fulfilled");
				}
			}
		} catch (error) {
			toast.error("Error updating item status: " + error);
			scheduleFetchOrders();
		} finally {
			processingCountRef.current -= 1;
			if (processingCountRef.current === 0 && pendingUpdateRef.current) {
				scheduleFetchOrders();
			}
		}
	};

	const handleOrderStatus = async (
		orderId: number,
		status = "",
		skipItemUpdate = false,
		manualOrder?: KDSOrder
	) => {
		const order = manualOrder ?? orders.find((order) => order.id === orderId);
		if (!order) {
			toast.error(`Order with id ${orderId} not found, can't update status to ${status}`);
			return;
		}

		processingCountRef.current += 1;

		if (status === "") {
			status =
				order.status === "pending"
					? "ready"
					: order.status === "ready"
					? "fulfilled"
					: "";
		}

		try {
			const updatedOrders = [...orders];
			const targetOrderIndex = updatedOrders.findIndex((o) => o.id === orderId);

			if (manualOrder) {
				// overwrite the order with the manualOrder, but update its status first
				const updatedOrder = { ...manualOrder, status };
				updatedOrders[
					targetOrderIndex !== -1 ? targetOrderIndex : updatedOrders.length
				] = updatedOrder;
				setOrders(updatedOrders);
			} else if (targetOrderIndex !== -1) {
				const isPickupAndDone = mode === "pickup" && status === "fulfilled";
				const isKitchenAndDone = mode === "kitchen" && status === "ready";

				if (isPickupAndDone || isKitchenAndDone) {
					updatedOrders.splice(targetOrderIndex, 1);
				} else {
					updatedOrders[targetOrderIndex].status = status;
				}
				setOrders(updatedOrders);
			}

			const response = await fetch(
				`/api/kds-orders/${orderId}/mark-${status}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json; charset=UTF-8",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					},
				}
			);

			if (!response.ok) {
				throw new Error(`Error marking order ready: ${response.statusText}`);
			}

			if (!skipItemUpdate) {
				if (status === "ready") {
					const itemsToMarkReady = order.items.filter(
						(item) => item.prepared_quantity < item.quantity
					);
					if (itemsToMarkReady.length > 0) {
						await Promise.all(
							itemsToMarkReady.map((item) =>
								fetch(`/api/kds-items/${item.id}/mark-prepared`, {
									method: "POST",
									headers: {
										"Content-Type": "application/json; charset=UTF-8",
										Authorization: `Bearer ${sessionStorage.getItem("token")}`,
									},
								})
							)
						);
					}
				} else if (status === "fulfilled") {
					const itemsToMarkFulfilled = order.items.filter(
						(item) => item.fulfilled_quantity < item.quantity
					);
					if (itemsToMarkFulfilled.length > 0) {
						await Promise.all(
							itemsToMarkFulfilled.map((item) =>
								fetch(`/api/kds-items/${item.id}/mark-fulfilled`, {
									method: "POST",
									headers: {
										"Content-Type": "application/json; charset=UTF-8",
										Authorization: `Bearer ${sessionStorage.getItem("token")}`,
									},
								})
							)
						);
					}
				}
			}
		} catch (error) {
			toast.error("Error marking order ready: " + error);
			scheduleFetchOrders();
		} finally {
			processingCountRef.current -= 1;
			if (processingCountRef.current === 0 && pendingUpdateRef.current) {
				scheduleFetchOrders();
			}
		}
	};

	const restoreOrder = async (orderId: number) => {
		processingCountRef.current += 1;
		const order = orders.find((o) => o.id === orderId);
	
		// ✅ Only remove the order from the list if it would disappear based on the current mode
		const shouldRemove =
			mode === "recall" || // recall mode hides pending orders
			(mode === "pickup" && order.status === "fulfilled");
	
		if (shouldRemove) {
			const updatedOrders = orders.filter((o) => o.id !== orderId);
			setOrders(updatedOrders);
		}
	
		try {
			// Update order status to pending
			const response = await fetch(`/api/kds-orders/${orderId}/mark-pending`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
				},
			});
	
			if (!response.ok) {
				throw new Error(`Error marking order pending: ${response.statusText}`);
			}
	
			// Update all item statuses to pending
			await Promise.all(
				order.items.map((item) =>
					fetch(`/api/kds-items/${item.id}/mark-pending`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
						},
					})
				)
			);
		} catch (error) {
			toast.error("Error marking order pending:" + error);
			scheduleFetchOrders();
		} finally {
			processingCountRef.current -= 1;
			if (processingCountRef.current === 0 && pendingUpdateRef.current) {
				scheduleFetchOrders();
			}
		}
	};
	
	
	

	const getItemSummary = (): Record<
		string,
		{ count: number; color: string }
	> => {
		// create item summary for footer
		const summary: Record<string, { count: number; color: string }> = {};
		orders.forEach((order) => {
			order.items.forEach((item) => {
				const unpreparedCount = item.quantity - item.prepared_quantity;
				if (unpreparedCount > 0) {
					if (!summary[item.item_name]) {
						summary[item.item_name] = {
							count: 0,
							color: `station-${item.station}`,
						};
					}
					summary[item.item_name].count += unpreparedCount;
				}
			});
		});

		//add total number of orders to end of summary
		summary["Total Orders"] = {
			count: orders.length,
			color: "#AAAAAA",
		};

		return summary;
	};

	const summary = getItemSummary();

	const HEADER_HEIGHT = 32; // Header/footer in pixels
	const ITEM_HEIGHT = 45; // Single line item height
	const INSTRUCTION_HEIGHT = 30; // Per instruction line
	const PADDING_HEIGHT = 24; // Padding per order

	const getAvailableHeight = (): number => {
		const carousel = document.querySelector(".kds-carousel");
		return carousel ? carousel.clientHeight : window.innerHeight * 0.95; // Adjust based on your container
	};

	useEffect(() => {
		//split orders by height if theyre too big for one column
		const splitOrderByHeight = (
			order: KDSOrder,
			maxHeight: number
		): KDSOrder[] => {
			const subOrders: KDSOrder[] = [];
			let currentItems: KDSItem[] = [];
			let currentHeight = HEADER_HEIGHT + PADDING_HEIGHT;

			order.items.forEach((item, idx) => {
				const itemHeight =
					ITEM_HEIGHT +
					(item.special_instructions
						? item.special_instructions.split(",").length * INSTRUCTION_HEIGHT
						: 0);

				// Check if adding the item exceeds max height
				if (currentHeight + itemHeight > maxHeight && currentItems.length > 0) {
					// Start new sub-order
					subOrders.push({
						...order,
						items: currentItems,
						continued: true,
						isFirst: subOrders.length === 0,
						isLast: false,
					});
					currentItems = [item];
					currentHeight = HEADER_HEIGHT + PADDING_HEIGHT + itemHeight;
				} else {
					currentItems.push(item);
					currentHeight += itemHeight;
				}

				// Final item handling
				if (idx === order.items.length - 1 && currentItems.length) {
					subOrders.push({
						...order,
						items: currentItems,
						isFirst: subOrders.length === 0,
						isLast: true,
					});
				}
			});

			return subOrders.map((subOrder, index) => ({
				...subOrder,
				isFirst: index === 0,
				isLast: index === subOrders.length - 1,
			}));
		};

		const splitOrders = labeledOrders.flatMap((order) =>
			splitOrderByHeight(order, availableHeight * 0.95)
		);
		setSplitOrders(splitOrders);
	}, [labeledOrders, availableHeight]);

	return (
		<div className="kds-container">
			<div className="kds-carousel">
				{splitOrders.map((order, index) => (
					<KdsOrderBlock
						key={`${order.id}-${index}`}
						order={order}
						handleOrderStatus={handleOrderStatus}
						handleItemToggle={handleItemToggle}
						restoreOrder={restoreOrder}
					/>
				))}
			</div>
			{mode === "kitchen" && (
				<div className="kds-summary">
					<ul>
						{Object.entries(summary).map(([itemName, { count, color }]) => (
							<li key={itemName} className={color}>
								{itemName}: {count}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default KDS;
